class SocketManager {
    constructor() {
        this.chatSocket = null;
        this.multiSocket = null;
        this.conferenceSocket = null;
    }
        
    static getInstance() {
        if(!this.instance) {
            this.instance = new SocketManager();
        }

        return this.instance;
    }

    setChatSocket(socket) {
        this.chatSocket = socket;
    }

    setMultiSocket(socket) {
        this.multiSocket = socket;
    }

    setConferenceSocket(socket) {
        this.conferenceSocket = socket;
    }

    closeChatSocket() {
        if(this.chatSocket) {
            this.chatSocket.close();
            this.chatSocket = null;
        }
    }

    closeMultiSocket() {
        if(this.multiSocket) {
            this.multiSocket.close();
            this.multiSocket = null;
        }
    }

    closeConferenceSocket() {
        if(this.multiSocket) {
            this.conferenceSocket.close();
            this.conferenceSocket = null;
        }
    }

}

export default SocketManager;