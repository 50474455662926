import Vuex from 'vuex';
import persistedstate from 'vuex-persistedstate';
import UserStore from '@/store/user/UserStore';

const store = new Vuex.Store({
    modules: {
      user: UserStore
    },
    plugins: [
        persistedstate({
            path:["UserStore"],
            storage: window.sessionStorage
        })
    ]
});

export default store;