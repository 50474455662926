class SceneManager {
    constructor() {
        this.scene = null;
        this.engine = null;
    }
        
    static getInstance() {
        if(!this.instance) {
            this.instance = new SceneManager();
        }
        return this.instance;
    }

    setScene(scene) {
        this.scene = scene;
    }

    setEngine(engine) {
        this.engine = engine;
    }

    closeScene() {
        if(this.scene) {
            this.scene.dispose();
            this.scene = null;
        }
    }

    closeEngine() {
        if(this.engine) {
            this.engine.dispose();
            this.engine = null;
        }
    }
}

export default SceneManager;