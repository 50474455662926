<template>
  <router-view />
  <span id="sessionTag">{{ isIdle }}</span>
</template>

<script>
import sceneManager from "@/sceneManager/sceneManager";
import socketManager from "@/socketManager/socketManager";

export default {
  computed: {
    isIdle() {
      if(this.$store.state.idleVue.isIdle) {
        this.gotoExpired();
      }

      return this.$store.state.idleVue.isIdle;
    }
  },
  methods: {
    gotoExpired() {
      const scene = sceneManager.getInstance();
      scene.closeScene();
      scene.closeEngine();

      const socket = socketManager.getInstance();
      socket.closeMultiSocket();
      socket.closeChatSocket();

      this.$router.push("/error/expired");
    }
  }
}
</script>

<style>
@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);

body, table, th, td, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6,
	pre, form, fieldset, textarea, blockquote, span, * {
	font-family: 'Noto Sans KR', sans-serif;
}
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
#sessionTag {
  display: none;
}
:focus {
  outline: 0;
}
a,
image,
img,
canvas{
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.babylonUnmuteIcon {
  display: none;
}
</style>
