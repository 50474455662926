import axios from 'axios';
import { useGtag } from "vue-gtag-next";

const UserStore = {
    state: {
        userInfo: {},
        lang: "ko",
        questsStatus: {
            count: 0,
            step: "",
            done: {
                step1: false,
                step2: false,
                step3: false
            },
            symbol: null
        },
        quality: "high",
        sound: "on",
        locate: "global",
        isMulti: false
    },
    mutations: {
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo;
        },
        setUserLang(state, lang) {
            state.lang = lang;
        },
        setQuestsStatus(state, status) {
            state.questsStatus = status;
        },
        setQuality(state, quality) {
            state.quality = quality;
        },
        setSound(state, sound) {
            state.sound = sound;
        },
        setLocate(state, locate) {
            state.locate = locate;
        },
        setUserCharacterColor(state, color) {
            if(state.userInfo) {
                state.userInfo.avatarColor = color;
            }
        },
        setIsMulti(state, isMulti) {
            state.isMulti = isMulti;
        }
    },
    getters: {
        getUserInfo(state) {
            return state.userInfo;
        },
        getUserLang(state) {
            return state.lang;
        },
        getQuestsStatus(state) {
            return state.questsStatus;
        },
        getQuality(state) {
            return state.quality;
        },
        getSound(state) {
            return state.sound;
        },
        getLocate(state) {
            return state.locate;
        },
        getIsMulti(state) {
            return state.isMulti;
        }
    },
    actions: {
        async setUserInfo({commit}, params) {
            const userId = String(new Date().getTime() + Math.random());
            const nickname = params.nickname;
            const avatarType = params.avatarType;
            const avatarColor = params.avatarColor;

            commit("setUserInfo", {
                id: userId,
                _id: userId,
                nickname: nickname,
                avatarType: avatarType,
                avatarColor: avatarColor
            });
        },
        setLang({commit}, params) {
            commit("setUserLang", params.lang);
        },
        async setQuestStatus({commit, state}, params) {
            const data = {
                userId: state.userInfo.id,
                type: params.count > 2 ? "clear" : "stage",
                nickname: state.userInfo.nickname
            };

            const questsStatus = {
                count: params.count,
                step: params.step,
                done: state.questsStatus.done,
                symbol: null
            }
            
            const { event } = useGtag();

            // make symbol number when complete quests
            if(data.type === "clear") {
                const symbolNum =  Math.floor(Math.random() * (10 -1 + 1)) + 1;
                const symbol = `https://kfmetaverse.blob.core.windows.net/static/assets/quest_symbol/symbol-${symbolNum}.png`;
                data.symbol = symbol;
                questsStatus.symbol = symbol;

                event(`quests_clear`, {
                    'event_category' : 'quests',
                    'event_label' : 'quests'
                });
            }

            if(params.step === 1) {
                data.step1 = true;
                questsStatus.done.step1 = true;
            }
            else if(params.step === 2) {
                data.step2 = true;
                questsStatus.done.step2 = true;
            }
            else {
                data.step3 = true;
                questsStatus.done.step3 = true;
            }

            const result = await axios.post(`${process.env.VUE_APP_API_DATA_HOST}/scene/quest-log`,
                data
            );

            if(!result.data.success) return false;
            
            commit("setQuestsStatus", questsStatus);

            event(`quests_step${params.step}_complete`, {
                'event_category' : 'quests',
                'event_label' : 'quests'
            });

            return true;
        },
        setQuality({commit}, params) {
            commit("setQuality", params.quality);
        },
        setSound({commit}, params) {
            commit("setSound", params.sound);
        },
        setLocate({commit}, params) {
            commit("setLocate", params.locate);
        },
        setUserCharacterColor({commit}, params) {
            commit("setUserCharacterColor", params.color);
        },
        setUserIsMulti({commit}, params) {
            commit("setIsMulti", params.isMulti);
        },
    }
}

export default UserStore;